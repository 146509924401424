import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const boostApi = createApi({
  reducerPath: 'boostApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_BOOST,
  }),
  tagTypes: ['budget'],
  endpoints: (build) => ({
    getBudget: build.query({
      query: (userID) => `/boost/v1/budget/${userID}`,
      providesTags: ['budget'],
    }),
    postBudget: build.mutation({
      query: (userID) => ({
        url: `/boost/v1/budget/${userID}`,
        method: 'POST',
      }),
      invalidatesTags: ['budget'],
    }),
    postSuggestedBudget: build.mutation({
      query: ({ ownerId = 0, ownerType = '' }) => ({
        url: '/boost/v1/campaign/suggested',
        method: 'POST',
        body: {
          owner_id: ownerId,
          owner_type: ownerType,
        },
      }),
      invalidatesTags: ['budget'],
    }),
    postPayment: build.mutation({
      query: ({
        userID,
        UserID = '',
        amount = 0,
        currency = '',
        customerID = '',
        sourceID = '',
      }) => ({
        url: `/boost/v1/pay/${userID}`,
        method: 'POST',
        body: {
          UserID,
          amount,
          currency,
          customer_id: customerID,
          source_id: sourceID,
        },
      }),
    }),
    getTransactions: build.query({
      query: (userID) => `/boost/v1/transaction/${userID}`,
      providesTags: ['transactions'],
    }),
    getCampaigns: build.query({
      query: ({ userID, superadmin }) => ({
        url: `/boost/v1/campaign/${userID}`,
        method: 'GET',
        headers: {
          superadmin,
        },
      }),
      providesTags: ['campaigns'],
    }),
    postImgUrl: build.mutation({
      query: ({ img = '' }) => ({
        url: `/boost/v1/campaign/img/url`,
        method: 'POST',
        body: {
          img,
        },
      }),
    }),
    getAllCampaigns: build.query({
      query: (userID) => `/boost/v1/${userID}`,
      providesTags: ['campaigns'],
    }),
    postCampaign: build.mutation({
      query: ({
        userID,
        budgetExternal = 0,
        bugdetInternal = 0,
        endDate = '',
        name = '',
        ownerID = 0,
        ownerName = '',
        ownerType = '',
        startDate = '',
        status = '',
      }) => ({
        url: `/boost/v1/${userID}`,
        method: 'POST',
        body: {
          budget_external: budgetExternal,
          budget_internal: bugdetInternal,
          end_date: endDate,
          name,
          owner_id: ownerID,
          owner_name: ownerName,
          owner_type: ownerType,
          start_date: startDate,
          status,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    getCampaign: build.query({
      query: ({ userID, campaignID }) =>
        `/boost/v1/${userID}/campaign/${campaignID}`,
    }),
    putCampaign: build.mutation({
      query: ({ userID, campaignID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}`,
        method: 'PUT',
        body: {
          budget_external: 0,
          budget_internal: 0,
          campaign_id: 'string',
          created_at: '2024-06-18T06:20:00.062Z',
          end_date: '2024-06-18T06:20:00.062Z',
          name: 'string',
          notify: 'string',
          number_ads: 0,
          owner_id: 0,
          owner_name: 'string',
          owner_type: 'string',
          showed_notify: true,
          start_date: '2024-06-18T06:20:00.062Z',
          status: 'string',
          updated_at: '2024-06-18T06:20:00.062Z',
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    deleteCampaign: build.mutation({
      query: ({ userID, campaignID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['campaigns'],
    }),
    putCampaignStatus: build.mutation({
      query: ({ userID, campaignID, status, message, superadmin }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/status`,
        method: 'PUT',
        body: {
          status,
          message,
        },
        headers: {
          superadmin,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    postAd: build.mutation({
      query: ({
        userID,
        campaignID,
        copies = [''],
        cta,
        keyImage1 = '',
        keyImage2 = '',
        name = '',
      }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}`,
        method: 'POST',
        body: {
          copies,
          cta,
          key_image1: keyImage1,
          key_image2: keyImage2,
          name,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    putAd: build.mutation({
      query: ({
        userID,
        campaignID,
        adID,
        copies = [''],
        createdAt = '',
        cta,
        keyImage2 = '',
        keyImage1 = '',
        name = '',
        notify = '',
        showedNotify = true,
        status = '',
        updatedAt = '',
      }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}`,
        method: 'PUT',
        body: {
          ad_id: adID,
          copies,
          created_at: createdAt,
          cta,
          key_image1: keyImage1,
          key_image2: keyImage2,
          name,
          notify,
          showed_notify: showedNotify,
          status,
          updated_at: updatedAt,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
    deleteAd: build.mutation({
      query: ({ userID, campaignID, adID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['campaigns'],
    }),
    putAdStatus: build.mutation({
      query: ({ userID, campaignID, adID, status, message, superadmin }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}/status`,
        method: 'PUT',
        body: {
          status,
          message,
        },
        headers: {
          superadmin,
        },
      }),
      invalidatesTags: ['campaigns'],
    }),
  }),
});

export const {
  useGetBudgetQuery,
  usePostBudgetMutation,
  usePostSuggestedBudgetMutation,
  usePostPaymentMutation,
  useGetTransactionsQuery,
  useGetCampaignsQuery,
  usePostImgUrlMutation,
  useGetAllCampaignsQuery,
  usePostCampaignMutation,
  useGetCampaignQuery,
  usePutCampaignMutation,
  useDeleteCampaignMutation,
  usePutCampaignStatusMutation,
  usePostAdMutation,
  usePutAdMutation,
  useDeleteAdMutation,
  usePutAdStatusMutation,
} = boostApi;
