import { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import {
  CalendarEventIcon,
  BillboardIcon,
  StatisticIcon,
  TeamIcon,
} from '@boletia/blt-components';
import {
  ConfigIcon,
  HistoryIcon,
  ReservationIcon,
  ServicesIcon,
} from '@boletia/blt-components/src/Icons';
import { Label } from '@boletia/blt-ui';
import Iconify from '@boletia/blt-ui/src/components/Iconify';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import pick from 'lodash/pick';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import useAuth from '../../hooks/useAuth';
import useWufooFileds from '../../hooks/useWufooFileds';
import { roles } from '../../routes/require-rol';
import { useGetFilteredPaymentMethodsByEventQuery } from '../../store/api/payment-methods.api';
import useTester from '../../hooks/useTester';
import NewFeatureLabel from '../../components/new-feature/NewFeatureLabel';
import useIncode from '../../hooks/useIncode';
import CampaignIcon from '../../modules/adtech/assets/CampaignIcon';
import useEvent from '../../hooks/useEvent';
import { useGetTicketTypesByEventQuery } from '../../store/api/ticket-type.api';
import { setTicketTypes } from '../../store/slices/ticket-type.slice';

const useNavConfig = () => {
  const {
    user: { super_admin: isSuperAdmin },
    userProfile,
    auth,
  } = useAuth();

  const { is_in_checkout_v2: isInCheckoutV2 } = useEvent();

  const { enqueueSnackbar } = useSnackbar();
  const { isTester } = useTester({
    testerType: 'showCampaigns',
  });

  const wufooFields = useWufooFileds();
  const wufoo = 'https://boletia.wufoo.com/forms';

  const adminNavConfig = [
    ...(isSuperAdmin
      ? [
          {
            subheader: 'Super Admin',
            items: [
              {
                title: t('admin.menu.search'),
                path: '/dashboard/search',
                icon: <Iconify icon={'material-symbols:search'} />,
                step: 'profile',
              },
              {
                title: t('admin.menu.unarchive'),
                path: '/dashboard/unarchive',
                icon: (
                  <Iconify
                    icon={'material-symbols:unarchive-outline-rounded'}
                  />
                ),
                step: 'profile',
              },
              {
                title: t('admin.menu.changeOwnership'),
                icon: (
                  <Iconify icon="material-symbols-light:partner-exchange-outline-rounded" />
                ),
                step: 'change',
                path: '/dashboard/change-ownership',
              },
              {
                title: t('campaigns.superAdmin.activeCampaigns'),
                icon: <Iconify icon={'bi:badge-ad'} />,
                step: 'active-campaigns',
                path: '/dashboard/active-campaigns',
              },
              {
                title: t('admin.menu.manageAccounts'),
                icon: (
                  <Iconify
                    icon={'material-symbols:manage-accounts-outline-rounded'}
                  />
                ),
                step: 'manage-accounts',
                children: [
                  {
                    title: t('admin.menu.deleteAccounts'),
                    path: `/dashboard/delete-accounts`,
                    step: 'delete-accounts',
                  },
                  {
                    title: t('admin.menu.setPassword'),
                    path: `/dashboard/set-password`,
                    step: 'set-password',
                  },
                ],
              },
              {
                title: t('global.labels.setting'),
                icon: <Iconify icon={'uil:setting'} />,
                step: 'setting',
                children: [
                  {
                    title: 'Bines',
                    path: `/dashboard/bines`,
                    step: 'bines',
                  },
                  {
                    title: t('admin.menu.wallet'),
                    path: `/dashboard/wallet`,
                    step: 'wallet',
                  },
                ],
              },
            ],
          },
        ]
      : []),
    {
      subheader: 'Admin Boletia',
      items: [
        {
          title: t('admin.menu.events'),
          path: '/dashboard/events',
          icon: <CalendarEventIcon />,
          step: 'events',
        },
        {
          title: t('admin.menu.billboards'),
          path: '/dashboard/billboards',
          icon: <BillboardIcon />,
          step: 'billboards',
        },
        ...(isTester
          ? [
              {
                title: t('admin.menu.adTech'),
                path: '/dashboard/campaigns',
                icon: <CampaignIcon />,
              },
            ]
          : []),
        {
          title: t('admin.menu.statistics'),
          path: '/dashboard/statistics',
          icon: <StatisticIcon />,
          step: 'statistics',
        },
        {
          title: t('admin.menu.team'),
          path: '/dashboard/team',
          icon: <TeamIcon />,
          step: 'my-team',
        },
      ],
    },
    {
      subheader: 'Check in',
      items: [
        {
          title: t('admin.menu.eventsControl'),
          path: '/dashboard/check-in/events-control',
          icon: <Iconify icon="tabler:calendar-cog" />,
          step: 'events-control',
          tag: (
            <NewFeatureLabel
              startDate="2023-11-07"
              duration={15}
              sx={{
                cursor: 'pointer',
              }}
            />
          ),
        },
      ],
    },
  ];

  const useGetEventNavConfig = ({ data, refetch, changeStatus, event }) => {
    const params = useParams();
    const dispatch = useDispatch();
    const { setShowIncodeModalValue } = useIncode();
    const { isTester: isPaymentsTester } = useTester({
      testerType: 'showPaymentMethods',
    });

    const { data: paymentMethods } = useGetFilteredPaymentMethodsByEventQuery(
      params.event,
    );
    const { data: tickets } = useGetTicketTypesByEventQuery(params.event);

    useEffect(() => {
      if (tickets) {
        dispatch(setTicketTypes(tickets));
      }
    }, [tickets]);

    const viewByRole = (rol, excludedRol, menuItem) => {
      if (roles[auth?.roles] >= roles[rol] && auth?.roles !== excludedRol) {
        return menuItem;
      }
      return { title: '', path: '' };
    };
    const handleStatus = () => {
      if (!event.banner) {
        return enqueueSnackbar('Tu evento aún no cuenta con un banner', {
          variant: 'error',
        });
      }
      if (data.event.channel_type === 'digital_external') {
        if (!data.external_link) {
          return enqueueSnackbar(
            'Tu evento aún no cuenta con la url donde será transmitido',
            {
              variant: 'error',
            },
          );
        }
      }
      if (data.event.channel_type === 'digital') {
        const obj = pick({ ...wufooFields, formname: 'z1t23dl01mmnhzh' }, [
          'formname',
          'field130',
          'field1',
          'field2',
          'field6',
          'field7',
        ]);
        const params = new URLSearchParams(obj).toString();
        if (!data?.preferences?.digital || data?.preferences?.digital === 'f') {
          return window.open(`${wufoo}/?${params}`, '_blank').focus();
        }
      }

      if (!isSuperAdmin) {
        if (!userProfile?.user_profile?.incode_validate) {
          return setShowIncodeModalValue(true);
        }
      }

      return changeStatus({
        id: data.event.id,
        status:
          data.event.status === 'not_published' &&
          data.event.activated_at !== null
            ? 'disable'
            : 'enable',
      }).then((res) => {
        if (res.error) {
          if (res.error.data.code === 'EVENTS_USER_WITHOUT_INCODE_VALIDATION') {
            setShowIncodeModalValue(true);
          } else {
            enqueueSnackbar(t(`errors.${res.error.data.code}`), {
              variant: 'error',
            });
          }
        } else {
          enqueueSnackbar(
            data.event.status === 'not_published' &&
              data.event.activated_at !== null
              ? 'El evento esta inactivo ahora'
              : 'El evento esta activo ahora',
          );
        }
      });
    };

    const isDigitalEvent = () =>
      data?.event?.channel_type === 'neerme' ||
      data?.event?.channel_type === 'digital';

    const getLabel = () => {
      if (isDigitalEvent()) {
        return data.preferences.digital === 't' ? 'Desactivar' : 'Activar';
      }

      return data?.event.status === 'not_published' &&
        data?.event.activated_at !== null
        ? 'Desactivar'
        : 'Activar';
    };

    const changeChecked = () => {
      // refetch();
      if (!isDigitalEvent()) {
        return (
          data?.event.status === 'not_published' &&
          data?.event.activated_at !== null
        );
      }
      return data.preferences.digital === 't'
        ? data?.event.status === 'not_published' &&
            data?.event.activated_at !== null
        : false;
    };

    const eventNavConfig = [
      {
        subheader: roles[auth?.roles] >= roles.editor &&
          auth.roles !== 'analyst' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                textTransform: 'none',
              }}
            >
              <Typography sx={{ fontWeight: '700', fontSize: '12px' }}>
                {`EVENTO ${data?.event.id}`}
              </Typography>
              {data?.event.status === 'archived' ? (
                <Label color="primary">Archivado</Label>
              ) : (
                <FormControlLabel
                  control={
                    <Switch
                      disabled={data?.event.status === 'archived'}
                      checked={changeChecked()}
                      onClick={() => refetch()}
                    />
                  }
                  label={getLabel()}
                  onChange={() => handleStatus()}
                />
              )}
            </Box>
          ),
        items: [
          viewByRole('analyst', 'editor', {
            title: 'Insights',
            path: `/event/${data?.event.id}/dashboard`,
            icon: <Iconify icon="eva:grid-fill" />,
            step: 'dashboard',
            children: [
              {
                title: 'Ventas',
                path: `/event/${data?.event.id}/dashboard/sales`,
              },
              {
                title: 'Clientes',
                path: `/event/${data?.event.id}/dashboard/clients`,
              },
              {
                title: 'Funnel de ventas',
                path: `/event/${data?.event.id}/dashboard/funnel`,
              },
            ],
          }),
          viewByRole('editor', '', {
            title: 'Configuración de eventos',
            path: `/event/${data?.event.id}/conf`,
            icon: <ConfigIcon />,
            step: 'settings',

            children: [
              {
                title: 'Editar evento',
                path: `/event/${data?.event.id}/conf/edit`,
                alert:
                  event.about === null ||
                  event.about === undefined ||
                  event.about === '',
              },
              {
                title: 'Boletos',
                path: `/event/${data?.event.id}/conf/tickets`,
                alert: tickets === null || tickets === undefined,
              },
              {
                title: t(
                  `payments.${isPaymentsTester ? 'title' : 'paymentMethods'}`,
                ),
                path: `/event/${data?.event.id}/conf/payments`,
                alert: paymentMethods?.length < 1,
              },
              {
                title: 'Encuesta',
                path: `/event/${data?.event.id}/conf/surveys`,
                step: 'surveys',
              },
              data?.event?.use_seats && data?.event?.channel_type === 'onsite'
                ? {
                    title: 'Mapa',
                    path: `/event/${data?.event.id}/conf/map`,
                    step: 'maps',
                  }
                : { title: '', path: '' },
              data?.event?.channel_type === 'onsite' && isInCheckoutV2
                ? {
                    title: t('holds.title'),
                    path: `/event/${data?.event.id}/conf/holds`,
                    step: 'holds',
                    tag: (
                      <NewFeatureLabel
                        startDate="2024-01-11"
                        duration={15}
                        sx={{
                          cursor: 'pointer',
                        }}
                      />
                    ),
                  }
                : { title: '', path: '' },
            ],
          }),
          viewByRole('partner', '', {
            title: 'Servicios adicionales',
            path: `/event/${data?.event.id}/services`,
            icon: <ServicesIcon />,
            step: 'additional',
            children: [
              // {
              //   title: 'Cortesías digitales',
              //   path: `/event/${data?.event.id}/services/batch`,
              //   step: 'batches',
              // },
              {
                title: 'Cupones de descuento',
                path: `/event/${data?.event.id}/services/promotions`,
                step: 'promotions',
              },
              {
                title: 'Servicios',
                path: `/event/${data?.event.id}/services/services`,
                step: 'services',
              },
              viewByRole('owner', '', {
                title: 'Duplicar eventos',
                path: `/event/${data?.event.id}/services/duplicate`,
                step: 'duplicate',
              }),
            ],
          }),
          viewByRole('analyst', 'editor', {
            title: 'Reservaciones',
            path: `/event/${data?.event.id}/reservations/totals`,
            icon: <ReservationIcon />,
            step: 'reservations',
          }),
          // {
          //   title: 'Ventas',
          //   path: `/event/${data?.event.id}/sales`,
          //   icon: <RegisterIcon />,
          // },
          viewByRole('analyst', 'editor', {
            title: 'Estadísticas',
            path: `/event/${data?.event.id}/stats`,
            icon: <StatisticIcon />,
            step: 'event-statistics',
            tag: (
              <NewFeatureLabel
                startDate="2024-02-12"
                duration={14}
                sx={{
                  cursor: 'pointer',
                }}
              />
            ),
          }),
          viewByRole('partner', 'editor', {
            title: 'Historial',
            path: `/event/${data?.event.id}/history`,
            icon: <HistoryIcon />,
            step: 'history',
          }),
        ],
      },
    ];
    return eventNavConfig;
  };

  const getCheckinNavConfig = ({ data }) => {
    const checkinNavConfig = [
      {
        subheader: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textTransform: 'none',
            }}
          >
            <Typography
              sx={{ fontWeight: '700', fontSize: '12px' }}
            >{`EVENTO ${data?.event.id}`}</Typography>
          </Box>
        ),
        items: [
          {
            title: 'Check-in',
            icon: <Iconify icon="material-symbols:qr-code-scanner" />,
            step: 'checkin',
            children: [
              {
                title: 'Listado de Asistentes',
                path: `/check-in/${data?.event.id}/attendees`,
              },
              {
                title: 'Historial',
                path: `/check-in/${data?.event.id}/records`,
              },
            ],
          },
          {
            title: 'Puntos de acceso',
            path: `/check-in/${data?.event.id}/checkpoints`,
            icon: <Iconify icon="icon-park-outline:connection-point-two" />,
            step: 'access-points',
          },
        ],
      },
    ];
    return checkinNavConfig;
  };
  return { adminNavConfig, useGetEventNavConfig, getCheckinNavConfig };
};

export default useNavConfig;
